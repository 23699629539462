type DraftInfluencersPagination = {
  next: string
  previous: string
  total: number
  results: number[]
}

export function provideGetCurrentDraftInfluencers() {
  return function (
    draftId: number,
    offset = 0,
  ): Promise<DraftInfluencersPagination> {
    return $coreFetch.$get<DraftInfluencersPagination>(
      `/submission/draft/${draftId}/influencers/?limit=100&offset=${offset}`,
    )
  }
}
