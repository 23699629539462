import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { usePayinStore } from './payin'

import type Pack from '~/types/pack'

type PackApiResponse = Omit<Pack, 'cost' | 'groovies'> & {
  cost: string
  groovies: string
}

const state = () => ({
  raw_available: [] as Pack[],
  selected: 0 as number,
})

export type PayinPacksState = ReturnType<typeof state>

export const usePayinPacksStore = defineStore('payinPacks', {
  state: (): PayinPacksState => ({ ...state() }),
  actions: {
    SELECT_BY_ID(packId: number) {
      this.selected = packId
    },
    SET_AVAILABLES(newAvailable: Pack[]) {
      this.raw_available.splice(0, this.raw_available.length)

      newAvailable.sort((a, b) => {
        return b.cost - a.cost
      })
      for (let i = newAvailable.length - 1; i >= 0; i--)
        this.raw_available.push(newAvailable[i])
    },
    FETCH: async function () {
      const packs = await $coreFetch.$get<PackApiResponse[]>('/wallet/pack/')

      this.SET_AVAILABLES(
        packs.map((response) => {
          return {
            ...response,
            cost: Number(response.cost),
            groovies: Number(response.groovies),
          } as Pack
        }),
      )
      return packs
    },
  },
  getters: {
    PURCHASE_PRICE(): number {
      const payingStore = usePayinStore(getActivePinia())
      return payingStore.PURCHASE_AMOUNT
    },
    AVAILABLE(state): Pack[] {
      const ret = []

      for (let i = state.raw_available.length - 1; i >= 0; i--) {
        if (state.raw_available[i].cost > this.CAMPAIGN_COST * 1.15)
          ret.push(state.raw_available[i])
      }

      return ret
    },
    BEST_PACK(state): Pack | undefined {
      // AB Test 'pack-payment-page-multiplier' cells 'increased-multiplier' and 'bigger-spenders'
      let multiplier = 1.33 // product magic number

      for (const pack of state.raw_available) {
        if (this.CAMPAIGN_COST * multiplier < pack.cost) return pack

        multiplier -= 0.06 // product magic number
      }
    },
    CAMPAIGN_COST(): number {
      const payinStore = usePayinStore(getActivePinia())

      if (payinStore.PURCHASE_TYPE === 'sendtrack') {
        const cost = payinStore.MISSING_CREDITS || 0
        return Math.max(cost, 10)
      } else {
        return 10
      }
    },
    SELECTED_PACK_OBJ(state): Pack | undefined {
      if (this.IS_SELECTED as boolean) {
        return state.raw_available.find((elem) => {
          return elem.id === state.selected
        })
      }
    },
    SELECTED_PERCENT(): number {
      if (this.IS_SELECTED as boolean) {
        const pack = this.SELECTED_PACK_OBJ as Pack
        return Math.round((1 - pack.cost / pack.groovies) * 100)
      } else {
        return 0
      }
    },
    IS_SELECTED(state) {
      return state.selected > 0
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePayinPacksStore, import.meta.hot))
