import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useUserStore } from './user'

import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type { LegalData } from '~/types/legalData'

const initialState = () => {
  const out: LegalData = {
    birth_date: null,
    city: '',
    country: 0,
    entity: '',
    first_name: '',
    id: 0,
    is_liable: false,
    last_name: '',
    postal_code: '',
    street: '',
    user: 0,
    vat_number: '',
  }
  return out
}

const state = initialState

export type PayinBillingInfosLegalDataState = ReturnType<typeof state>

export const usePayinBillingInfosLegalDataStore = defineStore(
  'payinBillingInfosLegalData',
  {
    state: (): PayinBillingInfosLegalDataState => ({ ...state() }),
    actions: {
      SET(data: Record<keyof PayinBillingInfosLegalDataState, any>) {
        let key: keyof PayinBillingInfosLegalDataState

        // @ts-expect-error frfr
        for (key in data) this[key] = data[key]
      },
      RESET: function () {
        resetStoreToInitialState.bind(this)(initialState())
      },
      async FETCH() {
        try {
          const resp =
            await $coreFetch.$get<[PayinBillingInfosLegalDataState]>(
              '/wallet/legaldata/',
            )

          if (!resp[0]) return this.RESET()

          this.SET({
            ...resp[0],
          })
          return resp[0]
        } catch (_) {
          this.RESET()
        }
      },
      async UPDATE_ON_SERVER(
        newLegalData: Record<keyof PayinBillingInfosLegalDataState, any>,
      ) {
        const userStore = useUserStore(getActivePinia())

        const sendFn = this.CAN_PATCH
          ? $coreFetch.$patch<PayinBillingInfosLegalDataState>
          : $coreFetch.$post<PayinBillingInfosLegalDataState>
        let url = '/wallet/legaldata/'

        if (this.CAN_PATCH) url += `${this.id}/`

        const resp = await sendFn(url, {
          ...newLegalData,
          id: undefined,
          user: userStore.id,
        })

        return {
          ...resp,
        }
      },
    },
    getters: {
      INITIAL_STATE() {
        return initialState()
      },
      CAN_PATCH(state) {
        return state.id > 0
      },
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePayinBillingInfosLegalDataStore, import.meta.hot),
  )
}
