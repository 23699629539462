import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useDraftPromoStore } from './draftPromo'
import { useUserStore } from './user'

import { provideFetchCartPricingInfo } from '~/api-core/Campaigns/FetchCartPricingInfo'

import type { BackendDraftPricingInfo, DraftPricingInfo } from '~/types/draft'

const initialState = (): DraftPricingInfo => ({
  minimumCartCost: -1,
  isDraftFirstPayingCampaign: true,
})

export const useDraftPricingStore = defineStore('draftPricing', {
  state: (): DraftPricingInfo => ({ ...initialState() }),
  actions: {
    SET_CART_PRICING_INFO(newState: DraftPricingInfo): void {
      this.minimumCartCost = newState.minimumCartCost
      this.isDraftFirstPayingCampaign = newState.isDraftFirstPayingCampaign
    },
    RESET_CART_PRICING_INFO(): void {
      this.SET_CART_PRICING_INFO(initialState())
    },
    FETCH_CART_PRICING_INFO(): Promise<DraftPricingInfo> {
      const fetchCartPricingInfo = provideFetchCartPricingInfo($coreFetch)

      if (this.minimumCartCost !== initialState().minimumCartCost)
        return Promise.resolve(this.$state)

      return fetchCartPricingInfo()
        .then(
          ({
            minimum_cart_cost: minimumCartCost,
            has_updated_minimum_cart_cost: isDraftFirstPayingCampaign,
          }: BackendDraftPricingInfo) => {
            const newState = {
              minimumCartCost,
              isDraftFirstPayingCampaign,
            } satisfies DraftPricingInfo

            this.SET_CART_PRICING_INFO(newState)
            return newState
          },
        )
        .catch((err) => {
          this.RESET_CART_PRICING_INFO()
          return err
        })
    },
  },
  getters: {
    NEED_PURCHASE(): boolean {
      return this.MISSING > 0
    },
    REMAINING() {
      const pinia = getActivePinia()
      const userStore = useUserStore(pinia)
      const draftPromoStore = useDraftPromoStore(pinia)

      const currentGroovizAmount = userStore.grooviz
      const postPromotionCost = draftPromoStore.DRAFT_COST_AFTER_PROMO
      return Math.floor(currentGroovizAmount - postPromotionCost)
    },
    MISSING() {
      const pinia = getActivePinia()
      const userStore = useUserStore(pinia)
      const draftPromoStore = useDraftPromoStore(pinia)

      const currentGroovizAmount = userStore.grooviz
      const postPromotionCost = draftPromoStore.DRAFT_COST_AFTER_PROMO
      return Math.abs(Math.min(currentGroovizAmount - postPromotionCost, 0))
    },
    ADJUSTED_MISSING(state): number {
      const missing = this.MISSING
      return Math.max(missing, state.minimumCartCost)
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDraftPricingStore, import.meta.hot))
