import { gql, request as graphqlRequest } from 'graphql-request'
import { useQuerySubscription } from 'vue-datocms'

import {
  isEnabledPreview,
  PREVIEW_MODE_COOKIE_NAME,
} from '~/utils/dato/datoPreview'

import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import type { Preview } from '~/utils/dato/datoPreview'
import type { RequestDocument, Variables } from 'graphql-request'

async function draftEnabledToken(
  runtimeConfig: ReturnType<typeof useRuntimeConfig>,
) {
  if (!import.meta.client) return runtimeConfig.draftEnabledToken

  const preview = await $fetch<Preview>('/api/preview')
  if (isEnabledPreview(preview)) return preview.token

  return undefined
}

function bundleSafeToken(runtimeConfig: ReturnType<typeof useRuntimeConfig>) {
  return runtimeConfig.public.datocms.bundleSafeToken
}

export function request<TDocument = any>(
  endpoint: string,
  document: RequestDocument | TypedDocumentNode<TDocument, Variables>,
  authorization: string,
  variables?: Variables,
) {
  return graphqlRequest<TDocument, Variables>(endpoint, document, variables, {
    Authorization: authorization,
  })
}

function subscribeToDrafts<T>(
  query: string,
  token: string,
  initialData: T,
  environment: string,
  variables: Record<string, any> = {},
) {
  return useQuerySubscription({
    query,
    variables,
    token,
    initialData: ref(initialData),
    includeDrafts: true,
    environment,
  })
}

export function useGraphqlQuery() {
  const runtimeConfig = useRuntimeConfig()
  const previewModeCookie = useCookie(PREVIEW_MODE_COOKIE_NAME)
  const isPreviewMode = !!previewModeCookie.value || import.meta.dev
  const { endpoint, environment } = runtimeConfig.public.datocms
  const fullEndpoint = environment
    ? `${endpoint}/environments/${environment}`
    : isPreviewMode
      ? `${endpoint}/preview`
      : endpoint

  return {
    async queryDatoCms<TDocument>(
      document: RequestDocument | TypedDocumentNode<TDocument, Variables>,
      variables: Variables = {},
    ) {
      // FIXME: This is not working as expected and is blocking client-side rendering for the slug pages
      // const token = await (isPreviewMode
      //   ? draftEnabledToken(runtimeConfig)
      //   : bundleSafeToken(runtimeConfig))

      const token = bundleSafeToken(runtimeConfig)

      if (!token) return { data: ref<null>(null) }

      const fetchedData = await request(
        fullEndpoint,
        document,
        token,
        variables,
      )

      // FIXME: This is not working as expected and is blocking client-side rendering for the slug pages
      // if (import.meta.client && isPreviewMode) {
      //   return subscribeToDrafts(
      //     gql`
      //       ${document}
      //     `,
      //     token,
      //     fetchedData,
      //     environment,
      //     variables,
      //   )
      // }

      return { data: ref(fetchedData) }
    },
  } as const
}
