import { TagClassTemplate } from './template'

export default class Tag extends TagClassTemplate<'Tag'> {
  readonly id: number
  readonly name: string
  readonly type: number
  readonly parent_id: number

  constructor(json: {
    id: number
    name: string
    type: number
    parent_id?: number
  }) {
    super('Tag')
    this.id = json.id
    this.name = json.name
    this.type = json.type
    this.parent_id = json.parent_id ?? 0
  }

  public override toJSON(): this {
    return this
  }
}
