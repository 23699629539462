import { TagClassTemplate } from './template'

import type TagTypeKey from '~/types/tagTypeKey'

export default class TagType extends TagClassTemplate<'TagType'> {
  name: TagTypeKey
  parent_ids: number[]
  tag_ids: number[]
  id: number

  constructor(json: {
    id: number
    name: TagTypeKey
    parent_ids?: number[]
    tag_ids: number[]
  }) {
    super('TagType')
    this.id = json.id
    this.name = json.name
    this.parent_ids = json.parent_ids ?? <number[]>[]
    this.tag_ids = json.tag_ids
  }

  public override toJSON(): this {
    return this
  }
}
