import {
  extrapolateLanguageFromLocale,
  getLocaleFromLanguage,
  useLanguage,
} from '~/composables/useLanguage'

import { useRootStore } from '~/stores/root'
import { useUserStore } from '~/stores/user'
import { useUserBandStore } from '~/stores/userBand'
import { useUserBandSetStore } from '~/stores/userBandSet'

import type { Locale } from '~/types/locale'
import type { Composer } from 'vue-i18n'

/**
 * The only reason this is running smoothly is because very fortunatly "fetch.ts" comes before "pinia.ts" when ascii sorted.
 * If ever needed please reffer to https://nuxt.com/docs/guide/directory-structure/plugins#loading-strategy and either use a dependency model or a load order model to resolve any dependency issues.
 */
export default defineNuxtPlugin(async ({ $i18n }) => {
  if (import.meta.env.TEST) return

  const {
    $router: { currentRoute: route },
    $pinia,
    $i18n: { setLocale },
  } = useNuxtApp()
  const switchLocalePath = useSwitchLocalePath()
  const { getCurrentLanguage, setCurrentLanguage } = useLanguage()
  // We're extrapolating a little here, but as long as we're using the i18n module this should hold true.
  const { locale } = $i18n as Composer

  // Skipping user load for widgets page
  if (
    !import.meta.server ||
    route.value?.name?.toString().includes('influencer-widget-slug')
  )
    return

  const rootStore = useRootStore($pinia)
  const userStore = useUserStore($pinia)
  const userBandSetStore = useUserBandSetStore($pinia)
  const userBandStore = useUserBandStore($pinia)

  const user = await rootStore.USER_LOAD()

  // N.B. this was part of the user/LOAD store function, but would lose Nuxt context
  // between async calls, while directly calling these functions from within the plugin is ok
  if (user) {
    const [submissions] = await Promise.all([
      userStore.GET_USER_SUB_COUNT(),
      userBandSetStore.FETCH(),
      userBandStore.FETCH(),
    ])
    userStore.SET({ submissions })
  }

  await rootStore.ACCOUNTS_LOAD()

  // Make sure that target Locale is respected post serverside login
  const targetLocale = getLocaleFromLanguage(getCurrentLanguage())

  // When logged in we want to adjust app locale to what the user last selected
  if (userStore.IS_LOGGED_IN && locale.value !== targetLocale) {
    // Replace any duplicate `/` that might be generated by switchLocalePath :<
    const targetPath = switchLocalePath(targetLocale).replace(/\/+/gi, '/')

    await setLocale(targetLocale)
    await navigateTo(targetPath, { replace: true })
  } else if (locale.value !== targetLocale) {
    // If locale is not the one selected in the URL make sure to adjust
    await setCurrentLanguage(
      extrapolateLanguageFromLocale(locale.value as Locale),
    )
  }
})
