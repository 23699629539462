/**
 * See example on https://nuxt.com/docs/guide/directory-structure/plugins#vue-plugins.
 */
import VueGtag, { trackRouter } from 'vue-gtag-next'

export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    if (import.meta.env.TEST) return

    const gid = process.env.GOOGLE_ANALYTICS_ID ?? ''

    nuxtApp.vueApp.use(VueGtag, {
      property: {
        id: gid,
      },
      appName: 'groover.co',
      isEnabled: true,
    })
    trackRouter(nuxtApp.vueApp.$nuxt.$router, { useScreenview: true })
  },
})
