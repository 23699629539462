import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useInfluencersStore } from './influencers'
import { useTagStore } from './tag'
import { useUserStore } from './user'
import { useUserBandStore } from './userBand'

import type TagType from '~/entities/tagType'
import type { FilterCategory } from '~/enums/FilterCategory'
import type CampaignGoal from '~/types/campaignGoal'
import type { InteractionTypeKey } from '~/types/filters'

export type InteractionStats = Record<InteractionTypeKey, number>

const state = () => ({
  selected_goals: [] as CampaignGoal[],
  selected_tag_ids: [] as number[],
  backup_tag_ids: [] as number[],
  selected_influencer_ids: [] as number[],
  tag_stats: {} as Record<number, number>,
  parent_stats: {} as Record<number, number>,
  interaction_stats: {
    contacted: 0,
    not_contacted: 0,
    new: 0,
    with_highly_rated_by_me: 0,
    with_positive_response: 0,
  } as InteractionStats,
  loading: false,
  scrollPos: 0,
  displayFilters: false,
  expandedMenuIndex: -1,
  pillsOrder: [] as FilterCategory[],
})

export type MiscSendtrackFiltersState = ReturnType<typeof state>

/**
 * Checks if we receive inf. Ids that aren't in the vuex store and fetches them.
 *
 * @param dispatch - Dispatch.
 * @param storedIds - The ids we currently have in the store.
 * @param idsFromFilterResponse - The list of ids to check.
 */
async function checkForMissingInfs(
  storedIds: number[],
  idsFromFilterResponse: number[],
): Promise<void> {
  // find difference of infs and fetch them
  const missingIds = idsFromFilterResponse.filter(
    (id: number) => !storedIds.includes(id),
  )
  if (!missingIds.length) return

  // if there are missing infs, go get them and add them to the list
  const influencersStore = useInfluencersStore(getActivePinia())
  const batchResponse = await influencersStore.FETCH_SET_AXIOS(missingIds)
  await influencersStore.HANDLE_INFLUENCER_BATCH({
    response: batchResponse,
    config: {
      ...influencersStore.default_config,
      noFollow: true,
      noFetchRecommendation: true,
    },
  })
}

export const useMiscSendtrackFiltersStore = defineStore(
  'miscSendtrackFilters',
  {
    state: (): MiscSendtrackFiltersState => ({ ...state() }),
    actions: {
      SET_BACKUP_TAGS_IDS(idArray: number[]) {
        this.backup_tag_ids = idArray
      },
      SET_SELECTED_GOALS(goals: CampaignGoal[]) {
        this.selected_goals = goals
      },
      SET_SELECTED_TAG_IDS(idArray: number[]) {
        this.selected_tag_ids = idArray
      },
      SET_SELECTED_INFLUENCER_IDS(idArray: number[]) {
        this.selected_influencer_ids = idArray
      },
      SET_TAG_STATS(stats: Record<number, number>) {
        this.tag_stats = stats
      },
      SET_PARENT_STATS(stats: Record<number, number>) {
        this.parent_stats = stats
      },
      SET_LOADING(payload: boolean) {
        this.loading = payload
      },
      SET_DISPLAY_FILTERS(payload: boolean) {
        this.displayFilters = payload
      },
      SET_SCROLL_POS(newPosition: number) {
        this.scrollPos = newPosition
      },
      SET_INTERACTION_STATS(interactionStats: InteractionStats) {
        this.interaction_stats = {
          ...this.interaction_stats,
          ...interactionStats,
        }
      },
      SET_PILLS_ORDER(newPillsOrder: FilterCategory[]) {
        this.pillsOrder = newPillsOrder
      },
      SET_EXPANDED_MENU(target: FilterCategory | 'none' | number) {
        const updateIndex = (i: number) => (this.expandedMenuIndex = i)

        if (typeof target === 'number') return updateIndex(target)

        const newIndex =
          target === 'none'
            ? -1
            : this.pillsOrder.findIndex(
                (categoryName) => categoryName === target,
              )
        updateIndex(newIndex)
      },
      FETCH_STATS(): Promise<boolean> {
        const pinia = getActivePinia()
        const userStore = useUserStore(pinia)
        const userBandStore = useUserBandStore(pinia)

        return new Promise((resolve, reject) => {
          Promise.all([
            Object.keys(this.tag_stats).length > 0
              ? this.tag_stats
              : $coreFetch.$get<Record<number, number>>(
                  '/influencer/tag_counts/',
                ),
            Object.keys(this.parent_stats).length > 0
              ? this.parent_stats
              : $coreFetch.$get<Record<number, number>>(
                  '/influencer/parent_counts/',
                ),
            userStore.IS_BAND
              ? $coreFetch.$get<InteractionStats>(
                  `/influencer/interaction_counts/?band_id=${userBandStore.id}`,
                )
              : this.interaction_stats,
          ])
            .then(
              ([tagStats, parentStats, interactionStats]: [
                Record<number, number>,
                Record<number, number>,
                InteractionStats,
              ]) => {
                this.SET_TAG_STATS(tagStats)
                this.SET_PARENT_STATS(parentStats)
                this.SET_INTERACTION_STATS(interactionStats)
                resolve(true)
              },
            )
            .catch(reject)
        })
      },
      FETCH(): Promise<boolean> {
        const tagStore = useTagStore(getActivePinia())

        return new Promise((resolve, reject) => {
          Promise.all([this.FETCH_STATS(), tagStore.FETCH()])
            .then(() => resolve(true))
            .catch((err) => reject(err))
        })
      },
      EXECUTE() {
        const influencersStore = useInfluencersStore(getActivePinia())

        this.SET_LOADING(true)

        return $coreFetch
          .$get<number[]>(`/influencer/query/${this.FORMATTED_QUERY}`)
          .then(async (resp) => {
            await checkForMissingInfs(influencersStore.IDS, resp)
            this.SET_SELECTED_INFLUENCER_IDS(resp)
          })
          .finally(() => {
            this.SET_LOADING(false)
          })
      },
      BACKUP_FILTER_SELECTION(): void {
        this.SET_BACKUP_TAGS_IDS([...this.selected_tag_ids])
      },
      REVERT_TO_BACKUP_SELECTION(): void {
        this.SET_SELECTED_TAG_IDS([...this.backup_tag_ids])
      },
      EMPTY() {
        this.SET_SELECTED_TAG_IDS([])
        this.SET_SELECTED_GOALS([])
      },
    },
    getters: {
      DISPLAY_FILTERS(state) {
        return state.displayFilters
      },
      NB_FILTER_SELECTED(state) {
        return state.selected_tag_ids.length
      },
      SEND_DATA(state): Record<TagType['name'], number[]> {
        const { types: tagTypes } = useTagStore(getActivePinia())

        return tagTypes.reduce(
          (accumulator, tagType) => {
            const tagIdArray = state.selected_tag_ids.filter((selectedTagId) =>
              tagType.tag_ids.includes(selectedTagId),
            )

            if (tagIdArray.length) accumulator[tagType.name] = tagIdArray

            return accumulator
          },
          {} as Record<TagType['name'], number[]>,
        )
      },
      FORMATTED_QUERY(_state): string {
        const entries = Object.entries(this.SEND_DATA) as [
          TagType['name'],
          number[],
        ][]
        const formatedStrings = entries.map(([key, values]) => ({
          key,
          values: values.join(','),
        }))
        const out =
          '?' +
          formatedStrings
            .map((entry) => `${entry.key}=${entry.values}`)
            .join('&')

        return out
      },
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMiscSendtrackFiltersStore, import.meta.hot),
  )
}
