import { loadStripe } from '@stripe/stripe-js'

import type { Stripe, StripePaymentElement } from '@stripe/stripe-js'
import type { Language } from '~/types/language'

function stripeKeyIsValid(key: unknown): key is string {
  return typeof key === 'string' && !!key
}

export default defineNuxtPlugin({
  parallel: true,
  async setup() {
    if (import.meta.env.TEST) {
      return {
        provide: {
          stripe: {} as Stripe,
        },
      }
    }

    const {
      public: { STRIPE_KEY },
    } = useRuntimeConfig()

    if (!stripeKeyIsValid(STRIPE_KEY) && !import.meta.env.TEST)
      throw new Error('MISSING STRIPE KEY !')

    const stripe = (await loadStripe(STRIPE_KEY, {
      apiVersion: '2020-08-27',
    })) as Stripe

    return {
      provide: {
        stripe,
      },
    }
  },
})

type ErrorType = 'card_error' | 'validation_error'

export type StripeElementError<T extends ErrorType = ErrorType> = {
  code: T extends 'card_error' ? 'incomplete_number' : 'card_declined'
  /**
   * This string is localized.
   */
  message: string
  type: T
}

export type StripeElementCardProperties = {
  locale?: (Language | 'auto')[]
  clientSecret: string
  appearance?: {
    theme?: 'stripe' | 'night' | 'flat' | 'none'
    labels?: 'above' | 'floating'
  }
  loader?: 'always' | 'never' | 'auto'
}

export type StripeConfirmOptionsProperties = {
  return_url: string
  redirect?: 'always' | 'if_required'
}

export type StripeElementCardReference = StripePaymentElement & {
  submit?: () => never
  update?: () => never
}
