import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useUserStore } from './user'

import { useRemoveCookie } from '~/composables/useRemoveCookie'

import { AvailableUserKinds } from '~/types/UserKind'

import type { UserKind } from '~/types/UserKind'

export interface MiscBandSignupWidgetContext {
  entityId: number
  userKind: UserKind
}

const state = () => ({
  isTransitioningToSignup: false,
  widgetContext: null as null | MiscBandSignupWidgetContext,
  soundcloudContext: {
    first_name: '',
    soundcloud_code: null as string | null,
    soundcloud_token: null as string | null,
  },
})

export type IMiscBandSignupState = ReturnType<typeof state>

function checkWidgetContext(
  candidate: any,
): candidate is MiscBandSignupWidgetContext {
  if (typeof candidate === 'object' && candidate !== null) {
    return (
      typeof candidate.entityId === 'number' &&
      Object.values(AvailableUserKinds).includes(candidate.userKind)
    )
  } else {
    return false
  }
}

export const useMiscBandSignupStore = defineStore('miscBandSignup', {
  state: (): IMiscBandSignupState => ({ ...state() }),
  actions: {
    SET_SOUNDCLOUD_SIGNUP_STATUS(
      status: IMiscBandSignupState['soundcloudContext'],
    ) {
      this.soundcloudContext = status
    },
    SET_TRANSITIONING_STATUS(isTransitioning: boolean) {
      this.isTransitioningToSignup = isTransitioning
    },
    SET_WIDGET_CONTEXT(newContext: MiscBandSignupWidgetContext | null) {
      this.widgetContext = newContext
    },
    SYNC_WIDGET_CONTEXT() {
      const userStore = useUserStore(getActivePinia())

      if (userStore.id > 0) {
        // TODO: check this cookie usage - especially the maxAge
        const storedContextJson = useCookie(this.WIDGET_CONTEXT_STORAGE_KEY, {
          maxAge: 7 * 24 * 3600, // 1 week in seconds
        })
        // this.$cookies.get(
        //   getters['WIDGET_CONTEXT_STORAGE_KEY'] as string
        // )

        // No serialized versions but local store version
        if (this.widgetContext !== null) {
          storedContextJson.value = JSON.stringify({ ...this.widgetContext })
        }
        // this.$cookies.set(
        //   getters['WIDGET_CONTEXT_STORAGE_KEY'],
        //   JSON.stringify({ ...state.widgetContext }),
        //   {
        //     maxAge: 7 * 24 * 3600, //1 week in seconds
        //   }
        // )
        // Has a serialized version
        else if (storedContextJson && storedContextJson.value) {
          try {
            const candidate = JSON.parse(storedContextJson.value) // JSON.parse(storedContextJson)

            if (checkWidgetContext(candidate))
              this.SET_WIDGET_CONTEXT(candidate)
          } catch (_err) {
            /**
             * Invalid JSON, this only happens when user
             * modifies the entry in the debugger.
             *
             * We'll just purge it from the registry.
             */
            // this.$cookies.remove(getters['WIDGET_CONTEXT_STORAGE_KEY'])
            useRemoveCookie(this.WIDGET_CONTEXT_STORAGE_KEY)
          }
        }
      }
    },
  },
  getters: {
    WIDGET_CONTEXT_STORAGE_KEY() {
      const userStore = useUserStore(getActivePinia())
      return `widgetContextJson${userStore.id}`
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMiscBandSignupStore, import.meta.hot),
  )
}
