import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser'

import { useUserStore } from '~/stores/user'

/**
 * Customer.io SDK to allow in-app messaging feature.
 * Note that this is a separate installation from Segment's Customer.io plugin)
 * and will not be used for tracking.
 * See https://docs.customer.io/cdp/sources/connections/javascript/js-source/ for more information.
 */
export default defineNuxtPlugin({
  parallel: true,
  setup() {
    if (import.meta.env.TEST) {
      return {
        provide: {
          cio: undefined as undefined | AnalyticsBrowser,
        },
      }
    }

    const {
      $router: { currentRoute: route },
    } = useNuxtApp()
    const userStore = useUserStore()

    const cio = AnalyticsBrowser.load(
      {
        // for EU - https://docs.customer.io/cdp/sources/connections/javascript/js-source/#eu-data-center
        cdnURL: 'https://cdp-eu.customer.io',
        writeKey: '338b8b018d828376eeaa',
      },
      {
        integrations: {
          'Customer.io In-App Plugin': {
            siteId: '6bf2f44ea83c8ef634b1',
          },
        },
      },
    )

    // identify user if they're already logged in and not on landing pages
    if (
      userStore.id &&
      (!route.value.name?.toString().includes('index___') ||
        route.value.path.includes('/lp/'))
    ) {
      cio.identify(userStore.id.toString(), {
        email: userStore.email,
      })
    } else if (!userStore.id) {
      cio.reset()
    }

    return {
      provide: {
        cio,
      },
    }
  },
})
