export function isDefined<T>(value: T | undefined): value is T {
  return typeof value !== 'undefined'
}

export function isNonNullAndDefined<T>(
  value: T | null | undefined,
): value is Exclude<T, null | undefined> {
  return isDefined(value) && value !== null
}

export function isNonNullObject(
  value: unknown,
): value is Record<string, unknown> {
  return isNonNullAndDefined(value) && typeof value === 'object'
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean'
}

export function isArray<T = unknown>(value: unknown): value is T[] {
  return value instanceof Array
}

export function isString(value: unknown): value is string {
  return typeof value === 'string'
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number'
}

export function isNonNanNumber(value: unknown): value is number {
  return isNumber(value) && !Number.isNaN(value)
}

export function isArrayOfType<T = unknown>(
  value: unknown,
  predicate: (e: unknown) => e is T,
): value is T[] {
  return isArray(value) && value.every(predicate)
}
