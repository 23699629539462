import { getActivePinia } from 'pinia'

import { provideCreateTrackForDraft } from '~/api-core/Track/TrackForDraft'

import type { TrackEmailSequenceQueryParams } from '~/types/track'
import type { LocationQuery } from 'vue-router'

export function useTrackFromEmailSequence() {
  const draftStore = useDraftStore(getActivePinia())

  async function createTrackFromEmailSequence(toQuery: LocationQuery) {
    if (!toQuery.name) return toQuery

    const queryParams = { ...toQuery }
    const {
      name,
      band_id: bandId,
      user_id: userId,
      spotify_id: spotifyId,
      soundcloud_id: soundcloudId,
    } = queryParams as unknown as TrackEmailSequenceQueryParams

    const createTrackForDraft = provideCreateTrackForDraft()
    const track = await createTrackForDraft({
      name,
      band: Number(bandId),
      user: Number(userId),
      spotify_id: spotifyId,
      soundcloud_id: soundcloudId,
    }).catch((error) => console.error(error))
    if (track) await draftStore.BIND_TRACK({ trackId: track.id })

    delete queryParams.name
    delete queryParams.band_id
    delete queryParams.user_id
    delete queryParams.spotify_id
    delete queryParams.soundcloud_id
    return queryParams
  }
  return {
    createTrackFromEmailSequence,
  }
}
