import uniqBy from 'lodash.uniqby'

import {
  DRAFT_STEP,
  DRAFT_STEP_ORDER,
  DRAFT_STEP_URL,
  PROGRESS_BAR_STEP_MAP,
} from '~/enums/DraftStep'

import type { DraftStepStatuses } from '~/types/draft'

export type DraftProgressBarStep = {
  key: `step${number}`
  title: `step ${number}`
  subtitle: `subtitlestep${number}`
  draftOrderIndex: number
}

export type DraftFlowState = {
  activeStep: number
  path: `/draft/${number}/`
  steps: DRAFT_STEP[]
  lockNavigation: boolean

  isTrackStepDone: boolean
  isSharedMusicStepDone: boolean
  isCuratorsSelectionStepDone: boolean
  isMessageStepDone: boolean
  isRecapStepDone: boolean

  progressBar: {
    step: number
    steps: DraftProgressBarStep[]
    stepsCount: number
  }
}

export const useDraftFlowStore = defineStore('draftFlow', () => {
  const activeStep = ref(0)
  const draftId = ref(0)
  const steps = ref<DRAFT_STEP[]>(DRAFT_STEP_ORDER)
  const path = computed<`/draft/${number}/`>(() => `/draft/${draftId.value}/`)
  const lockNavigation = ref(false)

  const CURRENT_STEP = computed(() => {
    return steps.value[activeStep.value]
  })

  const progressBar = computed(() => {
    const progressBarSteps = uniqBy(
      steps.value.map((draftStep, draftOrderIndex) => ({
        key: `step${PROGRESS_BAR_STEP_MAP[draftStep]}`,
        title: `step${PROGRESS_BAR_STEP_MAP[draftStep]}`,
        subtitle: `subtitlestep${PROGRESS_BAR_STEP_MAP[draftStep]}`,
        draftOrderIndex,
      })),
      'key',
    )

    return {
      step: PROGRESS_BAR_STEP_MAP[CURRENT_STEP.value],
      steps: progressBarSteps,
      stepsCount: progressBarSteps.length,
    }
  })

  const stepStatus = ref<DraftStepStatuses[]>([])
  const isTrackStepDone = computed(() =>
    stepStatus.value.includes(DRAFT_STEP.TRACK),
  )
  const isSharedMusicStepDone = computed(() =>
    stepStatus.value.includes(DRAFT_STEP.SHARED_MUSIC),
  )
  const isCuratorsSelectionStepDone = computed(() =>
    stepStatus.value.includes(DRAFT_STEP.CURATORS),
  )
  const isMessageStepDone = computed(() =>
    stepStatus.value.includes(DRAFT_STEP.MESSAGE),
  )
  const isRecapStepDone = computed(() =>
    stepStatus.value.includes(DRAFT_STEP.RECAP),
  )

  function SET_DRAFT_ID(id: number) {
    draftId.value = id
  }
  function GET_STEP_URL(stepNumber: number): string {
    return `${path.value}${DRAFT_STEP_URL[steps.value[Math.max(0, stepNumber)]]}`
  }
  function CHECK_ACTIVE_STEP(currentRoutePath: string) {
    lockNavigation.value = true
    // start AB test 'shared-music-step'
    if (
      currentRoutePath.includes(DRAFT_STEP_URL[DRAFT_STEP.SHARED_MUSIC]) &&
      !steps.value.includes(DRAFT_STEP.SHARED_MUSIC)
    ) {
      activeStep.value = steps.value.indexOf(DRAFT_STEP.CURATORS)
    }
    // end AB test 'shared-music-step'
    else {
      steps.value.forEach((_, index) => {
        if (currentRoutePath.includes(GET_STEP_URL(index)))
          activeStep.value = index
      })
    }
    lockNavigation.value = false
  }
  function GET_ACTIVE_STEP_URL(): string {
    return GET_STEP_URL(activeStep.value)
  }
  function GET_NEXT_STEP_URL(): string {
    return GET_STEP_URL(activeStep.value + 1)
  }

  function UPDATE_STEP_STATE_PROPERTIES(newStepStatus: DraftStepStatuses[]) {
    stepStatus.value = newStepStatus
  }
  /**
   * Figures out the furthest step that the user completed by checking for missing steps.
   * Note that even if a step is marked as done, if a previous step is missing,
   * it will return the step preceding the missing step.
   *
   * @param stepStatus - the step status array of the draft.
   * @returns the step the user should be on.
   */
  function GO_TO_FURTHEST_STEP(stepStatus: DraftStepStatuses[]) {
    UPDATE_STEP_STATE_PROPERTIES(stepStatus)
    const missingStep = steps.value
      .filter((s) => s !== DRAFT_STEP.SHARED_MUSIC) // shared music is optional
      .findIndex((step) => !stepStatus.includes(step))

    SET_ACTIVE_STEP(Math.min(steps.value.length - 1, missingStep))
  }
  function GO_TO_NEXT_STEP() {
    if (activeStep.value < steps.value.length - 1)
      return SET_ACTIVE_STEP(activeStep.value + 1)
  }
  function GET_PREVIOUS_STEP_URL(): string {
    return GET_STEP_URL(activeStep.value - 1)
  }
  function GO_TO_PREVIOUS_STEP() {
    if (activeStep.value > 0) return SET_ACTIVE_STEP(activeStep.value - 1)
  }
  function INIT_FROM_ROUTE(route: ReturnType<typeof useRoute>) {
    const routeDraftId = parseInt(route.params.id as string)
    if (!routeDraftId) return

    SET_DRAFT_ID(routeDraftId)
    SET_ACTIVE_STEP(
      Math.max(
        0,
        steps.value.findIndex((step) =>
          route.path.endsWith(DRAFT_STEP_URL[step]),
        ),
      ),
    )
  }
  function INSERT_STEP_AFTER({
    step,
    newStep,
  }: {
    step: DRAFT_STEP
    newStep: DRAFT_STEP
  }) {
    const stepIndex = steps.value.indexOf(step)
    if (stepIndex === -1) return

    const stepsCopy = [...steps.value]
    stepsCopy.splice(stepIndex + 1, 0, newStep)

    steps.value = [...new Set(stepsCopy)]
  }
  function INSERT_STEP_BEFORE({
    step,
    newStep,
  }: {
    step: DRAFT_STEP
    newStep: DRAFT_STEP
  }) {
    const stepIndex = steps.value.indexOf(step)
    if (stepIndex === -1) return

    const stepsCopy = [...steps.value]
    stepsCopy.splice(stepIndex, 0, newStep)

    steps.value = [...new Set(stepsCopy)]
  }
  function REMOVE_STEP(step: DRAFT_STEP) {
    const stepIndex = steps.value.indexOf(step)
    if (stepIndex === -1) return

    const stepsCopy = [...steps.value]
    stepsCopy.splice(stepIndex, 1)

    steps.value = [...new Set(stepsCopy)]
  }
  function SET_ACTIVE_STEP(newActiveStep: number) {
    activeStep.value = newActiveStep
  }
  function RESET() {
    SET_DRAFT_ID(0)
    SET_ACTIVE_STEP(0)
    UPDATE_STEP_STATE_PROPERTIES([])
  }

  return {
    activeStep,
    draftId,
    isMessageStepDone,
    isRecapStepDone,
    isCuratorsSelectionStepDone,
    isTrackStepDone,
    isSharedMusicStepDone,
    lockNavigation,
    path,
    progressBar,
    steps,
    CHECK_ACTIVE_STEP,
    CURRENT_STEP,
    GET_ACTIVE_STEP_URL,
    GET_NEXT_STEP_URL,
    GET_PREVIOUS_STEP_URL,
    GET_STEP_URL,
    GO_TO_FURTHEST_STEP,
    GO_TO_NEXT_STEP,
    GO_TO_PREVIOUS_STEP,
    INIT_FROM_ROUTE,
    INSERT_STEP_AFTER,
    INSERT_STEP_BEFORE,
    REMOVE_STEP,
    RESET,
    SET_ACTIVE_STEP,
    SET_DRAFT_ID,
    UPDATE_STEP_STATE_PROPERTIES,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDraftFlowStore, import.meta.hot))
