import { NuxtLink } from '#components'
import { useLocalePath } from '#imports'

/**
 * Register the NuxtLink component and useLocalePath composable globally
 * so the GrooverUI package can make use of them.
 * See https://github.com/nuxt/nuxt/issues/13659 for details.
 */
export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.vueApp.component('NuxtLink', NuxtLink)
    // @ts-expect-error - no need to define this property in TypeScript
    globalThis.useLocalePath = useLocalePath
  },
})
