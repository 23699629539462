<template>
  <div class="mainParentContainer ignoreFontUpscale tw-pt-6 md:tw-pt-12">
    <InfluencerOnboardingFlowThiagoBubble>
      {{ $t(`influencer.signup.bubble.name-country`) }}
    </InfluencerOnboardingFlowThiagoBubble>
    <div class="tw-max-w-3xl lg:tw-ml-48">
      <div class="tw-mb-8 tw-flex tw-flex-col tw-gap-2">
        <VTextInput
          v-model="nameFieldValue"
          :name="nameFieldName"
          data-test-id="curatorSignupPlaylistNameInput"
          :placeholder="$t('influencer.signup.name-country.name.placeholder')"
          :label="$t('influencer.signup.name-country.name.label')"
          :p-validity="!nameFieldErrorMessage"
        />
        <VText
          v-if="!nameFieldErrorMessage"
          cfg="sans/14/regular"
          color="gray-500"
        >
          {{ $t('influencer.signup.name-country.name.helper') }}
        </VText>
        <VText v-else cfg="sans/14/medium" color="error">
          {{ $t('influencer.signup.name-country.name.error') }}
        </VText>
      </div>
      <div class="tw-mb-8 tw-flex tw-flex-col tw-gap-2">
        <VSelect
          v-model="countryFieldValue"
          :name="countryFieldName"
          :placeholder="
            $t('influencer.signup.name-country.country.placeholder')
          "
          :label="$t('influencer.signup.name-country.country.label')"
          :available="COUNTRIES"
          label-key="name"
          value-key="id"
          data-test-id="curatorSignupCountrySelect"
          :transform-label-fn="transformLabelFn"
          :class="{ ignoreUnfold: false }"
          :p-validity="!countryFieldErrorMessage"
        />
        <VText
          v-if="countryFieldErrorMessage"
          cfg="sans/14/medium"
          color="error"
        >
          {{ $t('influencer.signup.name-country.country.error') }}
        </VText>
      </div>
    </div>
    <InfluencerOnboardingFlowNavigationFooter
      hide-previous-button
      @next="handleNext"
    />
  </div>
</template>

<script lang="ts">
import { getActivePinia, mapActions, mapState, mapStores } from 'pinia'
import { useForm } from 'vee-validate'
import { z } from 'zod'

import InfluencerOnboardingFlowNavigationFooter from '~/components/influencer/signup/InfluencerOnboardingFlowNavigationFooter.vue'
import InfluencerOnboardingFlowThiagoBubble from '~/components/influencer/signup/InfluencerOnboardingFlowThiagoBubble.vue'
import TextInput from '~/components/inputs/text.vue'
import VSelect from '~/components/ui/Inputs/VSelect.vue'
import VTextInput from '~/components/ui/Inputs/VText.vue'

import { useFieldValidation } from '~/composables/useFieldValidation'
import { useScrollFieldWithErrorIntoView } from '~/composables/useScrollFieldWithErrorIntoView'
import { useSortTagsByCountry } from '~/composables/useTagHelpers'

import { useInfluencerOnboardingFlowStore } from '~/stores/influencerOnboardingFlow'
import { useTagStore } from '~/stores/tag'
import { useUserStore } from '~/stores/user'
import { useUserInfluencerStore } from '~/stores/userInfluencer'
import { useUserInfluencerTagsStore } from '~/stores/userInfluencerTags'

import { provideCreateInfluencer } from '~/api-core/Curators/CreateInfluencer'
import { provideCuratorRefreshCache } from '~/api-core/Curators/CuratorRefreshCache'
import { providePatchInfluencer } from '~/api-core/Curators/PatchInfluencer'

import type Tag from '~/entities/tag'
import type TagType from '~/entities/tagType'
import type { Influencer } from '~/types/influencer'

export default defineNuxtComponent({
  components: {
    InfluencerOnboardingFlowNavigationFooter,
    InfluencerOnboardingFlowThiagoBubble,
    TextInput,
    VSelect,
    VTextInput,
  },
  setup() {
    definePageMeta({
      layout: 'onboarding-flow',
      middleware: ['onboarding-flow'],
    })

    const router = useRouter()
    const { t } = useI18n()
    const pinia = getActivePinia()
    const userInfluencerStore = useUserInfluencerStore(pinia)
    const userInfluencerTagsStore = useUserInfluencerTagsStore(pinia)
    const influencerOnboardingFlowStore =
      useInfluencerOnboardingFlowStore(pinia)
    const { sortTagsByCountry } = useSortTagsByCountry()

    influencerOnboardingFlowStore.CHECK_ACTIVE_STEP(
      router.currentRoute.value.path,
    )

    const { values: formValues, validate: validateForm } = useForm<{
      name: string
      country: number
    }>()

    const nameFieldName = 'name'
    const countryFieldName = 'country'

    const { value: nameFieldValue, errorMessage: nameFieldErrorMessage } =
      useFieldValidation<string>(
        nameFieldName,
        z.string().min(1, t('influencer.signup.name-country.name.error')),
        { syncVModel: true, initialValue: userInfluencerStore.entity },
      )

    const { value: countryFieldValue, errorMessage: countryFieldErrorMessage } =
      useFieldValidation<number>(
        countryFieldName,
        z.number().min(1, t('influencer.signup.name-country.country.error')),
        {
          syncVModel: true,
          initialValue:
            userInfluencerTagsStore.identity.country.length > 0
              ? userInfluencerTagsStore.identity.country[0]
              : 0,
        },
      )

    return {
      router,
      formValues,
      validateForm,
      nameFieldName,
      nameFieldValue,
      nameFieldErrorMessage,
      countryFieldName,
      countryFieldValue,
      countryFieldErrorMessage,
      sortTagsByCountry,
    }
  },
  computed: {
    ...mapState(useTagStore, {
      GET_TAG_TYPE_FROM_NAME: 'GET_TYPE_FROM_NAME',
      GET_TAGS_FROM_IDS: 'GET_TAGS_FROM_IDS',
    }),
    ...mapStores(useUserStore),
    ...mapStores(useUserInfluencerStore),
    ...mapStores(useUserInfluencerTagsStore),
    USER() {
      return this.userStore.$state
    },
    INFLUENCER() {
      return this.userInfluencerStore.$state
    },
    INFLUENCER_TAGS() {
      return this.userInfluencerTagsStore.$state
    },
    COUNTRY_TAG_TYPE(): TagType | undefined {
      return this.GET_TAG_TYPE_FROM_NAME('country')
    },
    COUNTRIES(): Tag[] {
      return (
        this.GET_TAGS_FROM_IDS(this.COUNTRY_TAG_TYPE?.tag_ids ?? []) as Tag[]
      )
        .filter(({ name }) => !name.includes('_'))
        .sort((a, b) => this.sortTagsByCountry(a, b))
    },
  },
  methods: {
    ...mapActions(useInfluencerOnboardingFlowStore, {
      GO_TO_NEXT_STEP: 'GO_TO_NEXT_STEP',
    }),
    ...mapActions(useUserInfluencerStore, { SET_INFLUENCER: 'SET' }),
    ...mapActions(useUserInfluencerTagsStore, {
      UPDATE_INFLUENCER_TAGS_FROM_PATCH: 'UPDATE_FROM_PATCH',
    }),
    async createInfluencer(): Promise<Influencer> {
      return await provideCreateInfluencer($coreFetch)(this.USER.id, {
        entity: this.nameFieldValue,
      })
    },
    async patchInfluencerName(): Promise<Influencer> {
      return await providePatchInfluencer($coreFetch, this.INFLUENCER.id, {
        entity: this.nameFieldValue,
      })()
    },
    patchInfluencerCountry() {
      return this.UPDATE_INFLUENCER_TAGS_FROM_PATCH({
        patch: {
          identity: {
            country: [this.countryFieldValue],
          },
        },
        influencer_id: this.INFLUENCER.id,
      })
    },
    async refreshCache() {
      await provideCuratorRefreshCache($coreFetch)(this.INFLUENCER.id)
    },
    async handleNext() {
      const { valid, errors } = await this.validateForm()
      if (!valid) {
        useScrollFieldWithErrorIntoView(errors)
        return
      }

      try {
        const influencer = this.INFLUENCER.id
          ? await this.patchInfluencerName()
          : await this.createInfluencer()

        await this.patchInfluencerCountry()

        this.SET_INFLUENCER(influencer)

        await this.refreshCache()

        this.GO_TO_NEXT_STEP()
      } catch (error) {
        this.$sentry?.captureException(error)
      }
    },
    transformLabelFn(value: string): string {
      return this.$t(`tags.country.${value}`) as string
    },
  },
})
</script>
