import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useDraftStore } from './draft'
import { useMiscBandSignupReferralStore } from './miscBandSignupReferral'
import { useMiscDraftProgressivePromosStore } from './miscDraftProgressivePromos'
import { useMiscSendtrackStore } from './miscSendtrack'
import { UserBandSetState } from './userBandSet'

import { useGetTotalPromo } from '~/composables/useGetTotalPromo'

import { computePercentPromotionToRemoveOnCost } from '~/helpers/promos'
import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type { ProgressivePromo } from '~/types/product'

const initialState = () => ({
  id: 0,
  code: '',
  is_percentage: false,
  auto_apply: false,
  actions: {
    campaign: {
      value: 0,
      is_percentage: true,
    },
    hype: {
      value: 0,
      is_percentage: true,
    },
  },
  progressive_parameters: undefined as
    | undefined
    | ProgressivePromo['progressive_parameters'],
})

const state = initialState
export type DraftPromoState = ReturnType<typeof state>

export const useDraftPromoStore = defineStore('draftPromo', {
  state: (): DraftPromoState => ({ ...initialState() }),
  actions: {
    SET(patch: Partial<Record<keyof DraftPromoState, any>>) {
      for (const key in state()) {
        if (
          typeof this[key as keyof DraftPromoState] ===
          typeof patch[key as keyof DraftPromoState]
        )
          // TODO: check this
          // @ts-expect-error frr
          this[key] = patch[key]
        else if (key === 'value' && patch[key] === null)
          // make sure null values reset the 'value' key to 0 so we don't accidentally
          // keep old values that shouldn't be kept
          this[key] = 0
      }
    },
    async PROBE({
      code,
      onlyApplyIfBetter,
    }: {
      code: string
      onlyApplyIfBetter?: boolean
    }): Promise<void> {
      const pinia = getActivePinia()
      const miscDraftProgressivePromoStore =
        useMiscDraftProgressivePromosStore(pinia)
      const draftStore = useDraftStore(pinia)
      const miscSendtrackStore = useMiscSendtrackStore(pinia)

      if (
        miscDraftProgressivePromoStore.list
          .map((promo) => promo.code)
          .includes(code)
      ) {
        const promo = await this.CHECK_FOR_PROGRESSIVE_PROMO()

        if (promo === null) {
          await this.RESET()
          throw new Error('Invalid promo code')
        } else {
          try {
            await draftStore.UPDATE()
          } catch (_) {}
        }
      } else if (code !== this.code) {
        // const hasInvalidPercent = false

        try {
          const response = await $coreFetch.$post<DraftPromoState>(
            '/wallet/promo/',
            {
              code,
            },
          )

          const totalCurrentPromo = useGetTotalPromo(state())
          const totalNewPromo = useGetTotalPromo(response)

          if (!onlyApplyIfBetter || totalNewPromo > totalCurrentPromo) {
            this.SET(response)
            draftStore.UPDATE()
          }

          if (response.actions.hype.value > 0)
            draftStore.SET({ is_boosted_track: true })

          // TODO: is this necessary? IS_VALID_PERCENT is always false when very few influencers in draft
          // if (!this.IS_VALID_PERCENT) {
          //   miscSendtrackStore.SET_PROMO_USED_WITH_INVALID_PERCENT(response)
          //   hasInvalidPercent = true
          //   throw new Error('Percent not valid')
          // } else {
          //   miscSendtrackStore.SET_PROMO_USED_WITH_INVALID_PERCENT(null)
          // }
        } catch (err) {
          // if there's an error like a 404 that causes this catch to run
          // make sure to set the `SET_PROMO_USED_WITH_INVALID_PERCENT` to null

          // TODO: is this necessary? IS_VALID_PERCENT is always false when very few influencers in draft
          // if (!hasInvalidPercent)
          //   miscSendtrackStore.SET_PROMO_USED_WITH_INVALID_PERCENT(null)

          await this.RESET()
          throw err
        }
      } else if (this.IS_VALID_PERCENT) {
        // Do nothing
      } else {
        throw new Error('Percent not valid')
      }
    },
    TEST_VALIDITY() {
      return $coreFetch.$post('/wallet/promo/', {
        code: this.code,
      })
    },
    async RESET({ noServerUpdate = false } = {}): Promise<void> {
      const draftStore = useDraftStore(getActivePinia())

      if (!noServerUpdate) {
        await $coreFetch.$patch(`/submission/draft/${draftStore.id}/`, {
          promo: null,
        })
      }

      resetStoreToInitialState.bind(this)(initialState())
    },
    async CHECK_FOR_PROGRESSIVE_PROMO(): Promise<ProgressivePromo | null> {
      const pinia = getActivePinia()
      const miscDraftProgressivePromosStore =
        useMiscDraftProgressivePromosStore(pinia)
      const miscBandSignupReferralStore = useMiscBandSignupReferralStore(pinia)
      const draftStore = useDraftStore(pinia)

      if (draftStore.id === 0 || !miscDraftProgressivePromosStore.IS_ENABLED)
        return null

      const eligibleProgressivePromo =
        miscDraftProgressivePromosStore.PROGRESSIVE_PROMO_FROM_INF_COUNT(
          draftStore.influencers_count,
        )
      const DRAFT_COST_AFTER_PROMO = this.DRAFT_COST_AFTER_PROMO
      const DRAFT_COST_AFTER_PROGRESSIVE_PROMO =
        miscDraftProgressivePromosStore.DRAFT_COST_AFTER_PROGRESSIVE_PROMO

      if (!eligibleProgressivePromo && this.IS_PROGRESSIVE_PROMO) {
        // edge case: when a progressive promo was applied but user removed curators from draft and no progressive promo is eligible anymore
        await this.RESET()
        return null
      } else if (!eligibleProgressivePromo) {
        return null
      }
      return eligibleProgressivePromo || null
    },
    async CHECK_FOR_AUTO_APPLY_PROMO() {
      const pinia = getActivePinia()
      const draftStore = useDraftStore(pinia)
      const miscDraftStore = useMiscDraftStore(pinia)

      if (draftStore.id === 0) return null

      const draftAutoAppliedPromo = await $coreFetch.$post(
        `/submission/draft/${draftStore.id}/auto_apply_best_promo/`,
      )

      if (draftAutoAppliedPromo) {
        draftStore.SET(draftAutoAppliedPromo)
        miscDraftStore.SET_USES_SOUND_CLOUD_NEXT_PROMO_CODE(
          draftAutoAppliedPromo.promo.rule.includes(
            'soundcloud_nextpro is true',
          ),
        )
      }
    },
    async UPDATE_PROGRESSIVE_PROMO() {
      const draftStore = useDraftStore(getActivePinia())
      const promo = this.id || null

      await $coreFetch
        .$patch(`/submission/draft/${draftStore.id}/`, {
          promo,
        })
        .catch()
      if (!promo) this.SET(initialState())
    },
  },

  getters: {
    TO_REMOVE_FROM_COST(state): {
      campaign: number
      hype: number
    } {
      const discounts = {
        campaign: 0,
        hype: 0,
      }
      const draftStore = useDraftStore(getActivePinia())

      for (const action in state.actions) {
        const promoAction = action as keyof DraftPromoState['actions']
        const baseCost: number =
          action === 'campaign' ? draftStore.COST : draftStore.boosted_cost
        const product = state.actions[promoAction]

        if (this.id > 0 && this.code !== '') {
          if (product.is_percentage) {
            discounts[promoAction] = computePercentPromotionToRemoveOnCost({
              baseCost,
              value: product.value,
            })
          } else {
            discounts[promoAction] = Math.min(product.value, baseCost)
          }
        } else {
          discounts[promoAction] = 0
        }
      }

      return discounts
    },
    CAMPAIGN_COST_AFTER_PROMO(state): number {
      const draftStore = useDraftStore(getActivePinia())

      return state.id > 0
        ? draftStore.COST - this.TO_REMOVE_FROM_COST.campaign
        : draftStore.COST
    },
    BOOSTED_COST_AFTER_PROMO(state): number {
      const draftStore = useDraftStore(getActivePinia())

      if (!draftStore.is_boosted_track) return draftStore.boosted_cost

      return state.id > 0
        ? draftStore.boosted_cost - this.TO_REMOVE_FROM_COST.hype
        : draftStore.boosted_cost
    },
    DRAFT_COST_AFTER_PROMO(state): number {
      return this.CAMPAIGN_COST_AFTER_PROMO + this.BOOSTED_COST_AFTER_PROMO
    },
    DRAFT_COST(): number {
      const draftStore = useDraftStore(getActivePinia())
      return draftStore.COST + draftStore.boosted_cost
    },
    IS_VALID_PERCENT(state) {
      const draftStore = useDraftStore(getActivePinia())

      // TODO: check for percentages in upsells also?
      if (state.id > 0 && state.actions.campaign.is_percentage)
        return draftStore.COST >= Math.round(100 / state.actions.campaign.value)
      else return true
    },
    IS_PROGRESSIVE_PROMO(state) {
      const miscDraftProgressivePromosStore =
        useMiscDraftProgressivePromosStore(getActivePinia())
      return miscDraftProgressivePromosStore.list
        .map((promo) => promo.code)
        .includes(state.code)
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDraftPromoStore, import.meta.hot))
