import { TagClassTemplate } from './template'

export default class TagParent extends TagClassTemplate<'TagParent'> {
  readonly id: number
  readonly name: string
  readonly type: number

  constructor(json: { id: number; name: string; type: number }) {
    super('TagParent')
    this.id = json.id
    this.name = json.name
    this.type = json.type
  }

  public override toJSON(): this {
    return this
  }
}
