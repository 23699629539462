import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useDraftTrackStore } from './draftTrack'
import { useInfluencersStore } from './influencers'

import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

const initialState = () => ({
  list: [] as number[],
  trackId: 0 as number,
})

const state = initialState

export type InfluencersDuplicatesState = ReturnType<typeof state>

interface InfluencerDuplicateApiResponse {
  duplicates: {
    date_sent: string
    id: number
  }[]
}

export const useInfluencersDuplicatesStore = defineStore(
  'influencersDuplicates',
  {
    state: (): InfluencersDuplicatesState => ({ ...state() }),
    actions: {
      SET_LIST(newList: number[]) {
        this.list = newList
      },
      SET_TRACK_ID(trackId: number) {
        this.trackId = trackId
      },
      RESET() {
        resetStoreToInitialState.bind(this)(initialState())
      },
      async FETCH_DUPLICATE_SUBMISSIONS() {
        const pinia = getActivePinia()
        const influencersStore = useInfluencersStore(pinia)
        const draftTrackStore = useDraftTrackStore(pinia)
        const influencerIds = influencersStore.list.map(({ id }) => id)
        const trackId = draftTrackStore.id

        const response = await $coreFetch.$post<InfluencerDuplicateApiResponse>(
          `/track/track/${trackId}/deduplicate/`,
          {
            influencer_pks: influencerIds,
          },
          { timeout: 2500 },
        )
        const duplicateIds = (response?.duplicates ?? []).map(({ id }) => id)

        this.SET_LIST(duplicateIds)
        return duplicateIds
      },
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useInfluencersDuplicatesStore, import.meta.hot),
  )
}
