import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useDraftStore } from './draft'
import { useUserStore } from './user'

import { computePercentPromotionOnCost } from '~/helpers/promos'

import type { ProgressivePromo } from '~/types/product'

const initialState = () => ({
  list: [] as ProgressivePromo[],
})

export type ProgressivePromosState = ReturnType<typeof initialState>

export const useMiscDraftProgressivePromosStore = defineStore(
  'miscDraftProgressivePromos',
  {
    state: (): ProgressivePromosState => ({ ...initialState() }),
    actions: {
      SET_LIST(payload: ProgressivePromo[]) {
        this.list = payload
      },
      FETCH() {
        const userStore = useUserStore(getActivePinia())

        if (this.list.length || !userStore.IS_BAND) return Promise.resolve()

        return $coreFetch
          .$get<ProgressivePromo[]>('/wallet/progressive-promos/')
          .then((response) => {
            this.SET_LIST(response)
          })
      },
    },
    getters: {
      PROGRESSIVE_PROMO_FROM_INF_COUNT(state) {
        return (influencerCount: number): ProgressivePromo | null => {
          const promos = state.list.length ? state.list : null

          if (!promos) return null

          const eligiblePromos = promos.filter(
            (promo) =>
              influencerCount >= promo.progressive_parameters.min_influencers,
          )

          return (
            eligiblePromos.sort(
              (a, b) => b.actions.campaign.value - a.actions.campaign.value,
            )?.[0] ?? null
          )
        }
      },
      DRAFT_COST_AFTER_PROGRESSIVE_PROMO(): number {
        const pinia = getActivePinia()
        const draftStore = useDraftStore(pinia)

        const baseCost = draftStore.COST
        const promoToApply = this.PROGRESSIVE_PROMO_FROM_INF_COUNT(
          draftStore.influencers_count,
        )
        if (promoToApply) {
          return computePercentPromotionOnCost({
            baseCost,
            value: promoToApply.actions.campaign.value,
          })
        } else {
          return baseCost
        }
      },
      IS_ENABLED(state) {
        return state.list.length > 0
      },
      NEXT_PROGRESSIVE_PROMO_FROM_INF_COUNT(state): ProgressivePromo | null {
        const draftStore = useDraftStore(getActivePinia())
        const influencerCount = draftStore.influencers_count
        const promos = state.list.length ? state.list : null

        if (!promos) return null

        const eligiblePromos = promos.filter(
          (promo) =>
            influencerCount < promo.progressive_parameters.min_influencers,
        )
        const lowestEligiblePromo = eligiblePromos.sort(
          (a, b) => a.actions.campaign.value - b.actions.campaign.value,
        )?.[0]
        return lowestEligiblePromo ?? null
      },
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMiscDraftProgressivePromosStore, import.meta.hot),
  )
}
