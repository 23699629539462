import { consola } from 'consola'

import type { RuntimeConfig } from 'nuxt/schema'
import type { FetchContext } from 'ofetch'

export function onResponse(
  context: FetchContext<any, any>,
  config?: RuntimeConfig,
) {
  const isDev =
    config?.public.ENV_NAME === 'dev' || import.meta.env.DEV || process.env.DEV
  if (!isDev) return

  const method = context.options.method ?? 'GET'
  const url = context.request.toString() ?? 'No url provided'
  const resStatusCode = context?.response?.status
  consola.success(`[NETWORK] - $${method}() on ${url} with ${resStatusCode}`)
}
