import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useInfluencersStore } from './influencers'
import { useUserBandStore } from './userBand'

import {
  createGrooverBucket,
  grooverBucketIsEqualToIdentification,
} from '~/helpers/favorites/grooverBucket'

import type { IGrooverBucket } from '~/helpers/favorites/grooverBucket'
import type GrooverBucket from '~/helpers/favorites/grooverBucket'
import type { StatsV3Influencer } from '~/types/influencer'

const state = () => ({
  buckets: [] as GrooverBucket[],
})

export type UserFavoritesRecommendedState = ReturnType<typeof state>

export const useUserFavoritesRecommendedStore = defineStore(
  'userFavoritesRecommended',
  {
    state: (): UserFavoritesRecommendedState => ({ ...state() }),
    actions: {
      SET_BUCKETS(bucketArray: GrooverBucket[]) {
        this.buckets = bucketArray
      },
      async FETCH_BUCKETS() {
        const userBandStore = useUserBandStore(getActivePinia())

        if (!userBandStore.id || this.buckets.length) return

        try {
          const timeout = 10000 // just in case reco service isn't responding and we need to free up concurrenty requests

          const buckets = await $coreFetch.$get<IGrooverBucket[]>(
            `/recommendation/groover_lists/?band_id=${userBandStore.id}`,
            { timeout },
          )

          this.SET_BUCKETS(buckets.map((bucket) => createGrooverBucket(bucket)))
        } catch (_) {}
      },
      FETCH_INFLUENCERS(): Promise<void> {
        const influencersStore = useInfluencersStore(getActivePinia())

        return new Promise((resolve) => {
          if (
            this.buckets.length &&
            this.GET_INFLUENCERS_IDS_FROM_ALL_BUCKETS.length
          ) {
            influencersStore
              .FETCH_SET(this.GET_INFLUENCERS_IDS_FROM_ALL_BUCKETS)
              .finally(() => {
                resolve()
              })
          } else {
            resolve()
          }
        })
      },
    },
    getters: {
      GET_INFLUENCERS_FROM_BUCKET(): (
        bucket: GrooverBucket,
      ) => StatsV3Influencer[] {
        const influencersStore = useInfluencersStore(getActivePinia())

        return function (bucket: GrooverBucket) {
          return influencersStore.GET_BY_IDS_SORTED(bucket.influencerIds)
        }
      },
      GET_INFLUENCERS_IDS_FROM_ALL_BUCKETS(state): number[] {
        return [
          ...new Set(
            state.buckets.reduce((accumulator, bucket) => {
              accumulator = [...accumulator, ...bucket.influencerIds]
              return accumulator
            }, [] as number[]),
          ),
        ]
      },
      GET_BUCKET_FROM_IDENTIFICATION_VALUE(state) {
        return function (identity: GrooverBucket['list_name']) {
          return state.buckets.find((grooverBucket) =>
            grooverBucketIsEqualToIdentification(grooverBucket, identity),
          )
        }
      },
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useUserFavoritesRecommendedStore, import.meta.hot),
  )
}
