import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import patcherShorthand from '~/mixins/store/patcher'

import { useUserStore } from '~/stores/user'

import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type {
  InfluencerLikedNeutralHatedTags,
  InfluencerTags,
} from '~/types/influencer'
import type TagParentTypeKey from '~/types/tagParentTypeKey'
import type TagTarget from '~/types/tagTarget'
import type TagTypeKey from '~/types/tagTypeKey'
import type { DeepPartial } from '~/types/utils'

const initialState = () => {
  const common = {
    subgenre: [],
    mood: [],
    format: [],
    artist_kind: [],
  } as InfluencerLikedNeutralHatedTags

  return {
    liked: {
      ...common,
    },
    hated: {
      ...common,
    },
    neutral: {
      ...common,
    },
    identity: {
      influencer_kind: [],
      country: [],
      influencer_badge: [],
    },
    exclusivity: {
      country: [],
      lyrics_lang: [],
      track_age: [],
    },
  } as InfluencerTags
}

// TODO if we already have InfluencerTags do we need the following one?
export type UserInfluencerTagsState = ReturnType<typeof initialState>

type InfluencerTagsTarget = keyof UserInfluencerTagsState

export const useUserInfluencerTagsStore = defineStore('userInfluencerTags', {
  state: (): UserInfluencerTagsState => ({ ...initialState() }),
  actions: {
    SET_TARGET<T extends InfluencerTagsTarget>({
      target,
      patch,
    }: {
      target: T
      patch: Partial<UserInfluencerTagsState[T]>
    }) {
      const availableTargets: InfluencerTagsTarget[] = [
        'liked',
        'hated',
        'neutral',
        'identity',
        'exclusivity',
      ]

      const lowerCaseTarget = target.toLowerCase() as T
      if (availableTargets.includes(lowerCaseTarget)) {
        this.$state[lowerCaseTarget] = patcherShorthand(this.$state, {
          target: lowerCaseTarget,
          patch,
        })
      } else {
        console.warn(
          `[INFLUENCER TAG] ${lowerCaseTarget} is not a valid target`,
        )
      }
    },
    RESET() {
      resetStoreToInitialState.bind(this)(initialState())
    },
    FETCH(slug?: string) {
      const userStore = useUserStore(getActivePinia())

      return new Promise((resolve, reject) => {
        if (userStore.IS_INF || userStore.is_staff) {
          const baseUrl = '/influencer/tags/'
          const url = slug ? `${baseUrl}?slug=${slug}` : baseUrl

          $coreFetch
            .$get<UserInfluencerTagsState>(url)
            .then((values) => {
              const keys = [
                'liked',
                'neutral',
                'hated',
                'exclusivity',
              ] as (keyof UserInfluencerTagsState)[]

              keys.forEach((target) => {
                this.SET_TARGET({
                  patch: values[target] ?? {},
                  target,
                })
              })
              this.SET_TARGET({
                patch: {
                  ...values.identity,
                  influencer_kind: values.identity?.influencer_kind ?? [],
                },
                target: 'identity',
              })
              resolve({ ...values })
            })
            .catch(reject)
        } else {
          resolve(null)
        }
      })
    },
    async UPDATE_FROM_PATCH({
      patch,
      influencer_id: influencerId,
    }: {
      patch: DeepPartial<InfluencerTags>
      influencer_id?: number
    }) {
      const baseUrl = '/influencer/tags/'
      const url = influencerId ? `${baseUrl}?id=${influencerId}` : baseUrl

      const patchedData = await $coreFetch.$post<InfluencerTags>(url, patch)
      this.SET_TARGET({ patch: patchedData.identity, target: 'identity' })
      return patchedData
    },
  },
  getters: {
    COUNTRY: (state) => state.identity.country[0] ?? 0,
    IS_MENTOR(state) {
      for (const key in state.identity?.influencer_kind ?? [])
        if (key.normalize().toLowerCase() === 'mentor') return true

      return false
    },
    BADGES(state) {
      return (state.identity?.influencer_badge ?? []) as number[]
    },
    // from tags mixin
    GET_DISALLOWED(state) {
      return function (
        category: keyof UserInfluencerTagsState,
        target: TagTypeKey | TagParentTypeKey,
      ) {
        category = category.toLowerCase() as keyof UserInfluencerTagsState
        const allowedKeys = Object.keys(state) as TagTarget[]
        const index = allowedKeys.findIndex(
          (key) => (key as string) === (category as string),
        )

        if ((category as string) === 'exclusivity') {
          return [] as number[]
        } else if (index !== -1) {
          allowedKeys.splice(index, 1)
          return [
            ...allowedKeys.reduce((accumulator, categoryKey) => {
              const subCat = state[categoryKey]

              // @ts-expect-error trust
              if (!subCat || !subCat[target]) return accumulator

              // @ts-expect-error trust
              subCat[target].forEach((tagId: number) => accumulator.add(tagId))
              return accumulator
            }, new Set()),
          ] as number[]
        }
        console.warn(
          `[TAGS]: ${category} is not a valid parameter in GET_DISALLOWED`,
        )
        return [] as number[]
      }
    },
    GET_TAGS(state) {
      return function (
        category: keyof UserInfluencerTagsState,
        target: TagTypeKey | TagParentTypeKey,
      ) {
        category = category.toLowerCase() as keyof UserInfluencerTagsState
        if (Object.keys(state).includes(category)) {
          // @ts-expect-error Can't make it
          const out: number[] | undefined = state?.[category]?.[target]

          if (typeof out !== 'undefined') {
            return out
          } else {
            console.warn(
              `[TAGS]: ${target} is not a valid parameter in GET_CATEGORY`,
            )
          }
        } else {
          console.warn(
            `[TAGS]: ${category} is not a valid parameter in GET_CATEGORY`,
          )
        }

        return []
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useUserInfluencerTagsStore, import.meta.hot),
  )
}
