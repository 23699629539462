import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useTagStore } from './tag'
import { useUserFavoritesStore } from './userFavorites'

import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type AgencyKind from '~/types/agencyKind'
import type TagTarget from '~/types/tagTarget'
import type TagTypeKey from '~/types/tagTypeKey'

type GuarantiedTagTypeKey = Extract<TagTypeKey, 'agency_jobs' | 'country'>

const createTagState = () => ({
  tags: {
    identity: {
      country: [],
      agency_jobs: [],
    },
  } as Partial<
    Record<
      TagTarget,
      Partial<
        Record<TagTypeKey, number[]> & Record<GuarantiedTagTypeKey, number[]>
      >
    >
  >,
})

const initialState = () => ({
  entity: '',
  id: 0,
  kind: '' as AgencyKind | '',
  last_excel_export_date: false as boolean | string,
  latest_campaign_date: null as null | string,
  promo: '',
  hidden_influencers: [] as number[],
  ...createTagState(),
})

export type UserAgencyState = ReturnType<typeof initialState>

export const useUserAgencyStore = defineStore('userAgency', {
  state: (): UserAgencyState => ({ ...initialState() }),
  actions: {
    SET(data: Partial<UserAgencyState>) {
      const ignoreTypesFor = [
        'last_excel_export_date',
        'country',
        'latest_campaign_date',
      ]
      const skipKeys = ['tags']

      let key: keyof UserAgencyState

      /**
       * Update non tag values.
       */
      for (key in data) {
        if (
          !skipKeys.includes(key) &&
          (ignoreTypesFor.includes(key) ||
            typeof this[key] === typeof data[key])
        )
          // TODO: check this ts-ignore
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore weird typing issue
          this[key] = data[key]
      }

      /**
       * If incoming data has tag data, update them.
       * This is necessary because backend sometime can return empty targetObjects.
       */
      if (data.tags) {
        Object.entries(data.tags).forEach(([targetKey, targetObject]) => {
          if (targetObject) {
            Object.entries(targetObject).forEach(([typeKey, typeObject]) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore typed checked beforehand
              this.tags[targetKey][typeKey] = typeObject
            })
          }
        })
      }
    },
    UPDATE_TAG_FROM_TARGET_AND_TYPE({
      tagTarget,
      tagType,
      patch,
    }: {
      tagTarget: TagTarget
      tagType: TagTypeKey
      patch: number[]
    }) {
      const toUpdate = this.tags[tagTarget]

      if (toUpdate) toUpdate[tagType] = patch
    },
    LOGOUT(): void {
      resetStoreToInitialState.bind(this)(initialState())
    },
    UPDATE_HIDDEN_INFLUENCERS(hiddenInfluencers: number[]): Promise<void> {
      const userFavoritesStore = useUserFavoritesStore()
      return $coreFetch
        .$patch<UserAgencyState>(`/agency/agency/${this.id}/`, {
          hidden_influencers: hiddenInfluencers,
        })
        .then((response) => {
          this.SET(response)
          userFavoritesStore.FETCH_BUCKETS({ fetchRecommended: true })
        })
        .catch(/** mute error */)
    },
  },
  getters: {
    HAS_COUNTRIES(state): boolean {
      const countries = state.tags.identity?.country
      return Boolean(countries && countries.length)
    },
    US_IS_ONLY_COUNTRY_FOR_AGENCY(state): boolean {
      const tagStore = useTagStore(getActivePinia())
      const countries = state.tags.identity?.country || []

      if (countries.length && countries.length === 1) {
        const tagName = tagStore.GET_TAG_FROM_ID(countries[0])?.name
        return Boolean(tagName && tagName === 'united-states')
      }

      return false
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserAgencyStore, import.meta.hot))
