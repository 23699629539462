import { useDraftStore } from '~/stores/draft'

import type { DraftPromoState } from '~/stores/draftPromo'

export function useGetTotalPromo(promo: DraftPromoState): number {
  let total = 0
  const draftStore = useDraftStore()
  const currentDraft = draftStore.state

  if (promo.id === 0 || promo.code === '') return 0
  for (const action in promo.actions) {
    const promoAction = action as keyof DraftPromoState['actions']
    const baseCost: number =
      action === 'campaign'
        ? currentDraft.draft_cost
        : currentDraft.boosted_cost
    const product = promo.actions[promoAction]

    if (product.is_percentage)
      total += Math.floor((baseCost * product.value) / 100)
    else total += baseCost - product.value
  }

  return total
}
