import { storeToRefs } from 'pinia'

import { useUserStore } from '~/stores/user'
import { useUserBandStore } from '~/stores/userBand'
import { useUserBandSetStore } from '~/stores/userBandSet'

const acceptedUrls = [
  '/band/profile/select',
  '/band/profile/delete',
  '/band/profile/create',
  '/influencer/signup',
]

export default defineNuxtRouteMiddleware(async (to) => {
  const { $localePath, $sentry, $pinia } = useNuxtApp()
  const userStore = useUserStore($pinia)

  if (
    !userStore.IS_LOGGED_IN ||
    userStore.IS_INF ||
    userStore.is_staff ||
    acceptedUrls.filter((e) => to.path.includes(e)).length
  )
    return

  const userBandStore = useUserBandStore($pinia)
  const { list: BAND_SET } = storeToRefs(useUserBandSetStore($pinia))

  const bandIsNotSelected = !userBandStore.id

  if (bandIsNotSelected) await userBandStore.FETCH()

  if (userStore.IS_BAND && bandIsNotSelected) {
    if (BAND_SET.value.length > 1)
      return navigateTo($localePath('/band/profile/select/'))

    try {
      await userBandStore.PATCH(BAND_SET.value[0].id)
    } catch (err) {
      $sentry?.captureException(err)
    }
  }
})
