import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useDraftStore } from './draft'
import { useDraftPricingStore } from './draftPricing'
import { usePayinPacksStore } from './payinPacks'
import { usePayinPaymentMethodStore } from './payinPaymentMethod'
import { usePayinPaymentMethodCardsStore } from './payinPaymentMethodCards'
import { usePayinPaymentMethodPaypalStore } from './payinPaymentMethodPaypal'
import { useUserStore } from './user'

import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

export const acceptablePayinOrigins = [
  'sendtrack',
  'unitary_pack',
  'pack',
] as const
export type AcceptablePayinOrigins = (typeof acceptablePayinOrigins)[number]

const state = () => ({
  acceptablePayinOrigins,
  processing_payment: false,
  current_og: '' as AcceptablePayinOrigins,
  unitary_pack_cost: 0,
})

export type PayinState = ReturnType<typeof state>

export const usePayinStore = defineStore('payin', {
  state: (): PayinState => ({ ...state() }),
  actions: {
    SET_CURRENT_ORIGIN(newOrigin: AcceptablePayinOrigins) {
      if (this.acceptablePayinOrigins.includes(newOrigin))
        this.current_og = newOrigin
    },
    SET_PROCESSING_STATUS(boolean: boolean) {
      if (typeof boolean === 'boolean') this.processing_payment = boolean
    },
    SET_UNITARY_PACK_COST(amount: number | string) {
      const type = typeof amount

      if (type === 'string' || type === 'number') {
        const converted = Number(amount)

        if (!isNaN(converted) && converted >= 0)
          this.unitary_pack_cost = Number(amount)
      }
    },
    RESET() {
      resetStoreToInitialState.bind(this)(state())
    },
    CREATE_CAMPAIGN({ draft, invoice }: { draft: number; invoice: boolean }) {
      return $coreFetch.$post<{ groovies: string; id: number }>(
        '/submission/campaign/',
        {
          draft,
          invoice,
        },
      )
    },
    async VALIDATE_TRANSACTION({
      draft,
      invoice = false,
    }: {
      draft?: number
      invoice: boolean
    }) {
      const pinia = getActivePinia()
      const userStore = useUserStore(pinia)
      const draftStore = useDraftStore(pinia)

      this.SET_PROCESSING_STATUS(true)

      const response = await this.CREATE_CAMPAIGN({
        draft: draft || draftStore.id,
        invoice,
      })
      userStore.SET_GROOVIZ(parseFloat(response.groovies))
      return response
    },
  },
  getters: {
    CAN_PAY(state): boolean {
      const pinia = getActivePinia()
      const { selected: type } = usePayinPaymentMethodStore(pinia)
      const { READY_TO_CONFIRM } = usePayinPaymentMethodPaypalStore(pinia)
      const { selected: selectedCard } = usePayinPaymentMethodCardsStore(pinia)

      if (!state.processing_payment) {
        if (type === 'paypal') return READY_TO_CONFIRM
        else return Boolean(type === 'card' && selectedCard !== null)
      } else {
        return false
      }
    },
    PURCHASE_TYPE(state): AcceptablePayinOrigins | '' {
      const userStore = useUserStore(getActivePinia())
      const hasValidPurchaseType = state.acceptablePayinOrigins.includes(
        state.current_og,
      )
      const requirements = {
        sendtrack: userStore.IS_BAND,
        unitary_pack:
          state.unitary_pack_cost >= 10 && state.unitary_pack_cost <= 10000,
        pack: true,
      } as const

      return hasValidPurchaseType && requirements[state.current_og] !== false
        ? state.current_og
        : ''
    },
    MISSING_CREDITS(state): number {
      const type = this.PURCHASE_TYPE
      const pinia = getActivePinia()
      const draftPricingStore = useDraftPricingStore(pinia)
      const payinPacksStore = usePayinPacksStore(pinia)

      if (type === 'sendtrack') return draftPricingStore.MISSING
      else if (type === 'unitary_pack') return state.unitary_pack_cost
      else if (type === 'pack')
        return payinPacksStore.SELECTED_PACK_OBJ?.cost ?? 0
      else return 0
    },
    PURCHASE_AMOUNT(state): number {
      const type = this.PURCHASE_TYPE
      const pinia = getActivePinia()
      const { ADJUSTED_MISSING } = useDraftPricingStore(pinia)
      const { IS_SELECTED } = usePayinPacksStore(pinia)

      if (type === 'sendtrack') return ADJUSTED_MISSING

      if (type === 'unitary_pack') return state.unitary_pack_cost

      if (type === 'pack' && IS_SELECTED) return 0

      return 0
    },
    FINAL_PURCHASE_AMOUNT(): number {
      const payinPacksStore = usePayinPacksStore(getActivePinia())

      if (!payinPacksStore.IS_SELECTED) return this.PURCHASE_AMOUNT
      else return payinPacksStore.SELECTED_PACK_OBJ?.cost as number
    },
    FINAL_PURCHASE_AMOUNT_CENTS(): number {
      // Number.EPSILON prevents rounding errors
      return Math.round((this.FINAL_PURCHASE_AMOUNT + Number.EPSILON) * 100)
    },
    FINAL_CREDITS_PURCHASE_AMOUNT(): number {
      const pinia = getActivePinia()
      const payinPacksStore = usePayinPacksStore(pinia)
      const { ADJUSTED_MISSING } = useDraftPricingStore(pinia)

      if (payinPacksStore.IS_SELECTED)
        return payinPacksStore.SELECTED_PACK_OBJ?.groovies as number

      if (this.PURCHASE_TYPE === 'sendtrack') return ADJUSTED_MISSING
      else return this.MISSING_CREDITS
    },
    STANDARD_EVENT_DATA() {
      const pinia = getActivePinia()
      const payinPacksStore = usePayinPacksStore(pinia)
      const { first_payment_date: firstPaymentDate } = useUserStore(pinia)

      return {
        currency: 'EUR',
        value: this.FINAL_PURCHASE_AMOUNT,
        content_ids: [this.PURCHASE_TYPE],
        content_type: 'product',
        first_payment: firstPaymentDate,
        is_pack: payinPacksStore.IS_SELECTED,
        pack_name: payinPacksStore.IS_SELECTED
          ? payinPacksStore.SELECTED_PACK_OBJ?.name
          : undefined,
        is_shop: false,
      }
    },
    LOOSE_EVENT_DATA(): {
      $revenue: number
      type: AcceptablePayinOrigins | ''
      first_payment: string | false
      is_pack: boolean
      pack_name?: string
    } {
      const pinia = getActivePinia()
      const payinPacksStore = usePayinPacksStore(pinia)
      const userStore = useUserStore(pinia)

      return {
        $revenue: this.FINAL_PURCHASE_AMOUNT,
        type: this.PURCHASE_TYPE,
        first_payment: userStore.first_payment_date,
        is_pack: payinPacksStore.IS_SELECTED,
        pack_name: payinPacksStore.IS_SELECTED
          ? payinPacksStore.SELECTED_PACK_OBJ?.name
          : undefined,
      }
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePayinStore, import.meta.hot))
